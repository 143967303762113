import React, { useState } from 'react';
//
import Logo from '../../assets/logo.png';
//
import { constants } from '../../constants';
//
import { FaInstagramSquare } from "react-icons/fa";
//
import styles from './footer.module.css';

export default function Footer() {

    //STATE
    const [easterEgg, setEasterEgg] = useState(0);

    //BODY
    return (
        <footer className={styles['container']}>
            <section className={styles['links-footer']}>

                <div className={styles['column-footer']}>
                    <img
                        className={styles['logo-footer']}
                        src={Logo}
                        alt="logo_img"
                    />

                    <span className={styles['logo-text']}>
                        Somos uma empresa brasileira com visão global,
                        que impulsiona conexões mais inteligentes e de
                        livre acesso.
                    </span>

                    <div className={styles['socials']}>
                        {constants.links.linkInstagram &&
                            <a
                                href={constants.links.linkInstagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FaInstagramSquare />
                            </a>
                        }
                    </div>
                </div>

                <div className={styles['column-footer']}>
                    <span className={styles['title-column']}>
                        Links Úteis
                    </span>
                    <a
                        className={styles['link-footer']}
                        href="#sobre-nos"
                    >
                        Sobre Nós
                    </a>
                    <a
                        className={styles['link-footer']}
                        href="#planos"
                    >
                        Como comprar na {constants.texts.nomeEmpresa}
                    </a>
                    <a
                        className={styles['link-footer']}
                        href="#faq"
                    >
                        FAQ
                    </a>
                </div>

                <div className={styles['column-footer']}>
                    <span className={styles['title-column']}>
                        Atendimento ao Cliente
                    </span>
                    <a
                        className={styles['link-footer']}
                        href="#pedir-chip"
                    >
                        Metódos de Pagamentos
                    </a>
                    <a
                        className={styles['link-footer']}
                        href="#pedir-chip"
                    >
                        Frete
                    </a>
                </div>

                <div className={styles['column-footer']}>
                    <span className={styles['title-column']}>
                        Minha Conta
                    </span>
                    {constants.links.linkRastrearMeuPedido &&
                        <a
                            className={styles['link-footer']}
                            href={constants.links.linkRastrearMeuPedido}
                        >
                            Rastrear meu pedido
                        </a>
                    }
                    <a
                        className={styles['link-footer']}
                        href="#pedir-chip"
                    >
                        Pedir Meu Chip
                    </a>
                </div>

                <div className={styles['column-footer']}>
                    <span className={styles['title-column']}>
                        Legal
                    </span>
                    {constants.links.linkPoliticaDePrivacidade &&
                        <a
                            className={styles['link-footer']}
                            href={constants.links.linkPoliticaDePrivacidade}
                        >
                            Política de Privacidade
                        </a>
                    }
                    {constants.links.linkPoliticaDePrivacidade &&
                        <a
                            className={styles['link-footer']}
                            href={constants.links.linkPoliticaDePrivacidade}
                        >
                            Termos e Condições
                        </a>
                    }
                </div>

            </section>

            <section
                className={styles['copyright']}
                onClick={() => { setEasterEgg(easterEgg + 1) }}
            >
                <span className={styles['text-copyright']}>
                    Copyright © 2022 PlayMóvel TELEFONIA MOVEL CELULAR LTDA - 33.093.462/0001-50 | All Rights Reserved
                </span>
                {easterEgg >= 10 &&
                    <span style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
                        Site feito por Gabriel BM2 Dev! :)
                    </span>
                }
            </section>

        </footer >
    )
}
