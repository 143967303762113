export const constants = {

    api: {
        parceiro: 'MI-MÓVEL',
    },

    texts: {
        nomeEmpresa: 'MI MÓVEL',
    },

    links: {
        linkPedirChip: null
        ,
        linkApp: 'https://infiniti.app/provedores/aplicativos/mimovel',
        linkWpp: 'https://api.whatsapp.com/send?phone=5555933001308&text=Quero%20saber%20da%20INFINITI',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: null,
        // linkTenhaSuaOperadora: 'https://api.whatsapp.com/send?phone=5561920040101&text=quero%20saber%20mais', // INFINITI Apenas
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/mimovel',
        linkInstagram: null,
    },

}